import React, { useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import ResponsiveContext from '../Responsive/context';

import Container from '../Container';
import Row from '../Container/row';
import Col from '../Container/column';

import './columns.css';

import { MainColour } from '../../utils/variables';

export default function HomepageColumns() {
  const { windowXXL } = useContext(ResponsiveContext);
  const data = useStaticQuery(graphql`
    query HomeColumnQuery {
      wpPage(slug: { eq: "home" }) {
        ...HomeCols
      }
    }
  `);
  const cols = data?.wpPage?.homepageLayout.columns;
  return (
    <div className="homepageCols" style={outerStyle}>
      <Container>
        <Row justify={windowXXL ? 'space-between' : 'center'}>
          {cols.map((item, i) => {
            const key = `homepageColumn${i}`;
            const { icon, content } = item;
            return (
              <Col
                key={key}
                className="item"
                xxl={{ width: `18%` }}
                lg={{ width: `30%` }}
                sm={{ width: `50%` }}
              >
                <div style={innerStyle}>
                  <div style={iconStyle}>
                    <img
                      src={icon.localFile.publicURL}
                      alt={content.title}
                      style={iconImage}
                    />
                  </div>
                  <div style={contentStyle}>
                    <h2 style={titleStyle}>{content.title}</h2>
                    <p style={textStyle}>{content.text}</p>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
}

const outerStyle = {
  marginTop: `60px`,
};

const innerStyle = {
  display: `flex`,
  flexDirection: `column`,
  alignItems: `center`,
  justifyContent: `center`,
};

const iconSize = `160px`;

const iconStyle = {
  width: iconSize,
  height: iconSize,
  borderRadius: iconSize,
  backgroundColor: MainColour,
  display: `flex`,
  alignItems: `center`,
  justifyContent: `center`,
};

const imageSize = `55px`;

const iconImage = {
  height: imageSize,
  width: imageSize,
};

const contentStyle = {
  textAlign: `center`,
  marginTop: `30px`,
};

const titleStyle = { fontSize: `24px`, marginBottom: `20px` };

const textStyle = { fontSize: `15px` };
